import _ from 'lodash'

/**
 * Calculates the profile completeness percentage based on the provided user object.
 * @param {Object} userObject - The user object containing profile information.
 * @returns {number} - The profile completeness percentage.
 */
const getProfileCompleteness = (userObject) => {
  const getVerified = (array) => array.some((el) => el.verified)
  /* eslint-disable-next-line camelcase */
  const { email_addresses, phones, rentalprofile } = userObject

  const progressFields = {
    bio: rentalprofile?.bio,
    age: rentalprofile?.age,
    occupation: rentalprofile?.occupation,
    nationality: rentalprofile?.nationality,
    has_clean_criminal_record:
      rentalprofile?.has_clean_criminal_record !== null,
    verifiedEmail: getVerified(email_addresses),
    verifiedPhone: getVerified(phones),
    language: !_.isEmpty(rentalprofile?.spoken_languages)
  }

  const all = _.values(progressFields)
  const filled = all.filter((v) => v)
  return Math.floor((filled.length / all.length) * 100)
}

const preventIllegalCharsForNumberInput = (e) => {
  // Allow digits, backspace, enter, arrow left, arrow right, delete
  const allowedKeys = [8, 13, 37, 39, 46]

  // For copy paste support
  const isCtrlOrCmd = e.ctrlKey || e.metaKey
  const isShortcutKey = ['c', 'v', 'x', 'a'].includes(e.key.toLowerCase())

  if (
    /^[0-9]$/.test(e.key) ||
    allowedKeys.includes(e.keyCode) ||
    (isCtrlOrCmd && isShortcutKey)
  ) {
    return // Allow
  }

  e.preventDefault() // Block all other keys
}

const preventIllegalCharsForTextInput = (e) => {
  const allowedKeys = [8, 13, 37, 39, 46]

  // For copy paste support
  const isCtrlOrCmd = e.ctrlKey || e.metaKey
  const isShortcutKey = ['c', 'v', 'x', 'a'].includes(e.key.toLowerCase())

  if (
    /^[a-zA-Z\s-]$/.test(e.key) ||
    allowedKeys.includes(e.keyCode) ||
    (isCtrlOrCmd && isShortcutKey)
  ) {
    return // Allow
  }

  e.preventDefault() // Block all other keys
}

export {
  getProfileCompleteness,
  preventIllegalCharsForNumberInput,
  preventIllegalCharsForTextInput
}
