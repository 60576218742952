import Vue from 'vue'

export const state = () => ({
  conversations: {
    items: [],
    count: null
  },
  conversationFilter: {
    page: 1,
    query: null
  },
  currentConversation: null,
  messages: {
    count: null,
    items: []
  },
  messagesFilter: {
    page: 1
  },
  events: [],
  quickMessageError: null,
  loadingConversation: false
})

export const mutations = {
  setConversations(state, { data, fetchNext = false }) {
    state.conversations.count = data.count
    if (fetchNext) {
      state.conversations.items = state.conversations.items.concat(data.items)
    } else {
      state.conversations.items = data.items
    }
    state.conversationFilter.page += 1
  },
  setConversationFilterPage(state, value) {
    state.conversationFilter.page = value
  },
  setConversationSearchQuery(state, value) {
    state.conversationFilter.query = value
  },
  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation
  },
  setMessages(state, { data, fetchNext = false }) {
    state.messages.count = data.count

    data.items.reverse()
    if (fetchNext) {
      state.messages.items = data.items.concat(state.messages.items)
    } else {
      state.messages.items = data.items
    }
    state.messagesFilter.page = state.messagesFilter.page + 1
  },
  setMessagesFilterPage(state, value) {
    state.messagesFilter.page = value
  },
  setLoading(state, value) {
    state.loadingConversation = value
  },
  addNewMessage(state, message) {
    state.messages.items.push(message)
    const i = state.conversations.items.findIndex(
      (convo) => convo.id === state.currentConversation.id
    )

    state.conversations.items = [state.currentConversation].concat(
      state.conversations.items
    )
    if (i !== -1) {
      state.conversations.items.splice(i, 1)
    }
    Vue.set(state.conversations.items[0], 'last_message', message)
  },
  addNewChat(state, chat) {
    state.conversations.unshift(chat)
  },
  messageListingContextSuccess(state, response) {
    state.quickMessageError = null
  },
  messageListingContextFailure(state, error) {
    if (error.status === 400) {
      if (error.data.message) {
        state.quickMessageError = 'Vantar skilaboð'
      } else {
        state.quickMessageError = 'Villa kom upp, reyndu aftur'
      }
    } else {
      state.quickMessageError = 'Villa kom upp, reyndu aftur'
    }
  },
  addContracts(state, contracts) {
    state.currentConversation.contracts =
      state.currentConversation.contracts.concat(contracts)
  },
  addListings(state, listings) {
    state.currentConversation.listings =
      state.currentConversation.listings.concat(listings)
  },
  setEvents(state, { data }) {
    state.events = data
  },
  setRentalApplicationCanceled(state, application) {
    const index = state.currentConversation.applications.findIndex(
      (x) => x.id === application.id
    )
    Vue.set(
      state.currentConversation.applications[index],
      'canceled_at',
      application.canceled_at
    )
    Vue.set(state.currentConversation.applications[index], 'active', false)
  }
}

export const actions = {
  async fetchConversations(
    { state, commit },
    { searchQuery, fetchNext = false }
  ) {
    commit('setConversationSearchQuery', searchQuery)
    if (!fetchNext) {
      commit('setConversationFilterPage', 1)
    }

    const { data } = await this.$axios.get(`conversations/`, {
      params: {
        ...state.conversationFilter
      }
    })
    commit('setConversations', { data, searchQuery, fetchNext })
  },
  async fetchTeamConversations({ state, commit }, { slug, fetchNext = false }) {
    if (!fetchNext) {
      commit('setConversationFilterPage', 1)
    }

    const { data } = await this.$axios.get(`teams/${slug}/conversations`, {
      params: {
        ...state.conversationFilter
      }
    })
    commit('setConversations', { data, fetchNext })
  },
  async fetchMessages({ state, commit }, { id, fetchNext = false }) {
    if (!fetchNext) {
      commit('setMessagesFilterPage', 1)
    }
    const { data } = await this.$axios.get(`conversations/${id}/messages/`, {
      params: {
        ...state.messagesFilter
      }
    })
    commit('setMessages', { data, fetchNext })
  },
  sendMessage(context, { id, msg }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`conversations/${id}/messages/`, {
          message: msg
        })
        .then((response) => {
          context.commit('addNewMessage', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendListingContextMessage(context, { listingId, msg }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`listings/${listingId}/messages/`, {
          message: msg
        })
        .then((response) => {
          context.commit('messageListingContextSuccess', response)
          resolve()
        })
        .catch((error) => {
          context.commit('messageListingContextFailure', error.response)
          reject(error)
        })
    })
  },

  createChat(context, { userIds, message = null }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`conversations/`, {
          member_ids: userIds,
          ...(message ? { message } : {})
        })
        .then((response) => {
          context.commit('addNewChat', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchConversationDetails(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`conversations/${id}/`)
        .then((response) => {
          context.commit('setCurrentConversation', response.data)
          context.commit('setLoading', false)
          this.commit('user/setUserLastConversationId', id)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },

  async fetchEvents({ state, commit }, id) {
    const { data } = await this.$axios.get(`applications/${id}/events/`)
    commit('setEvents', { data })
  },

  connectContractsToConvo(context, { convoId, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`conversations/${convoId}/connect-contracts/`, payload)
        .then((response) => {
          context.commit('addContracts', response.data.contracts)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  connectListingsToConvo(context, { convoId, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`conversations/${convoId}/connect-listings/`, payload)
        .then((response) => {
          context.commit('addListings', response.data.listings)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async cancelRentalApplication({ commit }, id) {
    const { data } = await this.$repos.tenant.cancelRentalApplication(id)
    commit('setRentalApplicationCanceled', data)
  }
}
